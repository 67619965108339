import React, { useEffect, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import "./styles.css";

const items = ["Tamata", "Agent"];
const config = { mass: 5, tension: 2000, friction: 200 };

export function Splash() {
  const [toggle, set] = useState(true);
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  useEffect(() => {
    setTimeout(() => {
      set((state) => !state);
    }, 1500);
  }, []);

  return (
    <div className="trails-main" onClick={() => set((state) => !state)}>
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <a.div
            key={items[index]}
            className="trails-text"
            style={{
              ...rest,
              // textAlign: "end",
              lineHeight: "70px",
              transform: x.to((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <a.div style={{ height, color: "#eb0028" }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    </div>
  );
}
