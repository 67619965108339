import { ArrowBackIos } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ThemeProvider,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import { collection, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "./firebase";
import FullScreenDialog from "./fullScreenDialog";
import { Splash } from "./splash";
import { theme } from "./styles";
import ReactLogo from "./assets/tamata_agent_logo.svg";
import { ToastContainer } from "react-toastify";
import RtlProvider from "./RtlProvider";
import FormDialog from "./modal";

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  // ADDITIONAL INFO DIALOG
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const [regions, setRegions] = useState([]);

  const fetchRegions = async () => {
    const q = query(collection(db, "cities"));

    const unsubscriber = onSnapshot(q, (snapshot) => {
      var data = [];
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setRegions([...data]);
    });

    return unsubscriber;
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      {loading && <Splash />}
      {!loading && (
        <Stack
          sx={{
            paddingY: {
              mobile: "10%",
            },
            paddingX: {
              tablet: "20%",
              mobile: "10%",
            },
          }}
        >
          <img className="image" src={ReactLogo} alt="Tamata Agent Logo" />

          <Typography
            // width={200}
            variant="h6"
            style={{
              direction: "rtl",
              maxWidth: "90%",
              alignSelf: "end",
            }}
          >
            حتى تنضم لعائلتنا، وتصير توصّل طلبات طماطة تكَدر تسجل ويّانه وبكل
            سهولة عن طريق الضغط على (ملأ الاستمارة) و تملي المعلومات المطلوبة
          </Typography>
          <Box
            marginTop={7}
            style={{
              direction: "rtl",
            }}
          >
            <Stack direction={"row"} gap={2}>
              <Button
                disableElevation
                size="medium"
                style={{
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setOpen(true);
                  // setOpenInfoDialog(true);
                }}
                variant="contained"
                endIcon={<ArrowBackIos style={{ marginX: 2 }} />}
              >
                ملأ الاستمارة{" "}
              </Button>{" "}
              <Button
                size="medium"
                style={{
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setOpenInfoDialog(true);
                }}
                variant="text"
                endIcon={<ArrowBackIos style={{ marginX: 2 }} />}
              >
                معلومات إضافية{" "}
              </Button>{" "}
            </Stack>
          </Box>
        </Stack>
      )}
      <InfoDialog
        open={openInfoDialog}
        setOpen={setOpenInfoDialog}
        scroll={scroll}
      />
      {/* <FullScreenDialog open={open} setOpen={setOpen} regions={regions} /> */}
      <FormDialog open={open} setOpen={setOpen} />
    </ThemeProvider>
  );
};

export const InfoDialog = ({ open, setOpen, scroll }) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Dialog
      style={{
        margin: "auto",
        width: matches ? "70%" : "100%",
      }}
      open={open}
      onClose={() => setOpen(false)}
      scroll={scroll}
      maxWidth={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <RtlProvider>
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h6" color={"inherit"}>
            الشغل ويانة رح يكون سهل، وسلس، ويتلخص بهل نقاط:
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <List>
              <ListItem>
                • استلام الطلبات رح يكون من مقر الشركة في العرصات.
              </ListItem>
              <ListItem>• تسليم الطلبات في المنطقة المتفق عليها فقط.</ListItem>
              <ListItem>
                ◦ رح يكون أكو تطبيق خاص بيك هو تطبيق المندوب، ومن عنده رح تكَدر
                تعرف معلومات التوصيل وغيرها
              </ListItem>
              <ListItem>
                ◦ بالنسبة لأوقات إستلام الطلبات وتسليمها، رح تتفق عليه وية
                الشركة لما تجي تكمل أوراق تسجيلك
              </ListItem>
              <ListItem>
                ◦ أما بالنسبة لمستحقاتك المالية، فتتم بشكل يومي أو اسبوعي أو
                شهري، وحسب إتفاقك مع الشركة
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={"400"}>
                  الموضوع سهل، ومناسب إلك ولوقتك، كل اللي عليك تسوي، تكمل
                  التسجيل، وتجي لشركة طماطة وتتفق على الباقي
                </Typography>
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => setOpen(false)}>
            رجوع
          </Button>
        </DialogActions>
      </RtlProvider>
    </Dialog>
  );
};
