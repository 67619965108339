import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { db } from "./firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import RtlProvider from "./RtlProvider";

export default function FormDialog({ info, open, setOpen }) {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [validName, setValidName] = React.useState(false);
  const [validCity, setValidCity] = React.useState(false);
  const [validAge, setValidAge] = React.useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = React.useState(false);
  const [validVehicleType, setValidVehicleType] = React.useState(false);
  const [age, setAge] = React.useState(null);
  const [gender, setGender] = React.useState("male");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [vehicleType, setVehicleType] = React.useState(vehicleTypes[0].value);
  const [qualifications, setQualifications] = React.useState("");
  const [carInfo, setCarInfo] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [province, setProvince] = React.useState("بغداد");
  const [city, setCity] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleRegister = async () => {
    if (name.length < 2) {
      setValidName(true);
      return;
    } else {
      setValidName(false);
    }

    if (age > 70 || age < 14) {
      setValidAge(true);
      return;
    } else {
      setValidAge(false);
    }

    if (phoneNumber.length < 6) {
      setValidPhoneNumber(true);
      return;
    } else {
      setValidPhoneNumber(false);
    }
    if (city.length < 2) {
      setValidCity(true);
      return;
    } else {
      setValidCity(false);
    }

    try {
      setLoading(true);
      await addDoc(collection(db, "requests"), {
        name,
        phoneNumber,
        // city: info.city,
        // lat: info.lat,
        // lng: info.lng,
        age,
        gender,
        qualifications,
        carInfo,
        notes,
        createdAt: serverTimestamp(),
        status: "pending",
        province,
        city,
        vehicleType:vehicleType
      });
      setLoading(false);
      setOpen(false);
      toast.success(`شكراً ${name}، لقد تمت عملية التسجيل بنجاح.`, {
        style: {
          direction: "rtl",
        },
      });
    } catch (err) {
      setLoading(false);
      toast.warning(`نعتذر ${name}، لقد حصل خطأ، لم تتم عملية التسجيل بنجاح.`, {
        style: {
          direction: "rtl",
        },
      });
    }
  };
  return (
    <Dialog
      style={{
        direction: "rtl",
        // width: "50vw",
        margin: "auto",
      }}
      sx={{
        width: {
          laptop: "50vw",
          tablet: "70vw",
          mobile: "100vw",
        },
      }}
      fullScreen={false}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle style={{ color: "#EB0028" }}>الاستمارة</DialogTitle>
      <DialogContent>
        <DialogContentText>
          الرجاء، إدخال معلوماتك الصحيحة، وإضغط على إرسال حتى تصير جزء من
          عائلتنا
        </DialogContentText>
        <RtlProvider>
          <TextField
            // autoFocus
            error={validName}
            helperText={!validName ? "" : "إكتب اسمك الحقيقي"}
            required={true}
            margin="dense"
            placeholder="علي رعد خليل"
            id="name"
            label="الاسم"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setName(x.target.value)}
          />
        </RtlProvider>

        <RtlProvider>
          <Stack direction={"row"} gap={4}>
            <TextField
              required={true}
              error={validAge}
              helperText={!validAge ? "" : "إكتب عمرك الحقيقي"}
              placeholder="27"
              margin="dense"
              id="age"
              label="العمر"
              type="number"
              fullWidth
              variant="standard"
              onChange={(x) => setAge(x.target.value)}
            />

            <TextField
              margin="dense"
              id="gender"
              label="الجنس"
              defaultValue={"male"}
              select
              fullWidth
              variant="standard"
              onChange={(x) => setGender(x.target.value.toLowerCase())}
            >
              {["male", "female"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option === "male" ? "ذكر" : "أنثى"}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </RtlProvider>
        <RtlProvider>
          <TextField
            required={true}
            error={validPhoneNumber}
            helperText={!validPhoneNumber ? "" : "إكتب رقم موبايل حقيقي"}
            placeholder="07709092158"
            margin="dense"
            id="phoneNumber"
            label="رقم الهاتف"
            type="tel"
            fullWidth
            variant="standard"
            onChange={(x) => setPhoneNumber(x.target.value)}
          />
        </RtlProvider>
        <RtlProvider>
          <TextField
            placeholder="بكلوريوس ترجمة"
            margin="dense"
            id="qualifications"
            label="الشهادة و التحصيل الدراسي"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setQualifications(x.target.value)}
          />
        </RtlProvider>
        <RtlProvider>
        <TextField
         error={validVehicleType}
         helperText={!validVehicleType ? "" : "حدد نوع  المركبة"}
            margin="dense"
            id="car"
            defaultValue={"car"}
            label="نوع المركبة"
            select
            fullWidth
            variant="standard"
            onChange={(x) => setVehicleType(x.target.value)}
          >
            {vehicleTypes.map((option,index ) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </RtlProvider>

        <RtlProvider>
          <TextField
            placeholder="هونداي سوناتا 2020"
            margin="dense"
            id="carInfo"
            label="اسم و موديل السيارة"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setCarInfo(x.target.value)}
          />
        </RtlProvider>
        <RtlProvider>
          <TextField
            placeholder="سيارتي كبيرة و تتحمل الكثير من الطلبات."
            multiline
            id="notes"
            label="ملاحظات"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setNotes(x.target.value)}
          />
          <TextField
            margin="dense"
            id="province"
            label="المحافظة"
            defaultValue={"بغداد"}
            select
            fullWidth
            variant="standard"
            onChange={(x) => setProvince(x.target.value)}
          >
            {provinces.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </RtlProvider>
        <RtlProvider>
          <TextField
            error={validCity}
            helperText={!validCity ? "" : "اكتب اسم منطقتك"}
            required={true}
            placeholder="الكرادة مقابل جلود"
            // margin="dense"
            id="city"
            label="المنطقة"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setCity(x.target.value)}
          />
        </RtlProvider>
        <p style={{fontSize:'0.9rem'}}>للاستفسار أكثر عن معلومات الوظيفة يرجى الاتصال على هذا الرقم: <a dir={'ltr'} style={{textDecoration: 'none', color: "#EB0028"}} href="tel:+9647833252493">+964 783 325 2493</a></p>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          إلغاء
        </Button>
        <LoadingButton
          // style={{ color: "#EB0028" }}
          color="primary"
          loading={loading}
          onClick={handleRegister}
        >
          إرسال
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

let provinces = [
  "أربيل",
  "الأنبار",
  "بابل",
  "بغداد",
  "البصرة",
  "حلبجة",
  "دهوك",
  "القادسية",
  "ديالى",
  "ذي قار",
  "السليمانية",
  "صلاح الدين",
  "كركوك",
  "كربلاء",
  "المثنى",
  "ميسان",
  "النجف",
  "نينوى",
  "واسط",
];

let vehicleTypes = [
{value:"car",label:"سيارة"},
{value:"bike",label:"دراجة"},
];
